<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                  </ul>
                </p>
              </div>
              <!-- 公寓筛选 -->
              <div class="range" id="gysx">
                <h4 class="title">公寓筛选</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击页面左侧“公寓管理”栏目，跳转出公寓管理页面并显示当前园区所有公寓信息数据（如图5.1），可在页面上方白框对公寓进行筛选查询，也可点击蓝色字体“楼栋楼层”、“门牌名称”跳转至目标公寓
                </p>
                <el-image 
                style="width:100%"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图5.1</p>
              </div>

              <!-- 数据统计 -->
              <div class="range" id="sjtj">
                <h4 class="title">数据统计</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击公寓管理下方灰色方框“数据统计”跳转至公寓数据统计页面（如图5.2），可在红色方框画出区域进行公寓筛选随后显示对应公寓信息（房间号、付费类型、前后水电表读数、总用水电量、当前余额）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图5.2</p>
              </div>
              <!-- 充值记录 -->
              <div class="range" id="czjl">
                <h4 class="title">充值记录</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击公寓管理下方灰色方框“充值记录”跳转至公寓充值记录页面（如图5.3）可在红色方框画出区域进行公寓筛选随后显示对应公寓租户充值记录（房间号、承租公司、支付人、支付金额、支付时间、微信订单号）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图5.3</p>
              </div>
               <!--同步租约信息 -->
              <div class="range" id="tbzyxx">
                <h4 class="title">同步租约信息</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.在物业公寓系统内添加完客户信息后，回到园区首页，点击公寓管理页面右上方白色方框“同步租约信息”（如图5.4），待方框内圆圈旋转完毕即可完成租户信息同步
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图5.4</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "公寓筛选",
          target: "#gysx",
        },
        {
          name: "数据统计",
          target: "#sjtj",
        },
        {
          name: "充值记录",
          target: "#czjl",
        },
        {
          name: "同步租约信息",
          target: "#tbzyxx",
        },
      ],
      link: 0,
      img: {
        aurl: [this.$img + "gygl/5.1.png"],
        burl: [this.$img + "gygl/5.2.png"],
        curl: [this.$img + "gygl/5.3.png"],
        durl: [this.$img + "gygl/5.4.png"],
        eurl: [this.$img + "gygl/5.5.png"],
        furl: [this.$img + "gygl/5.6.png"],
        gurl: [this.$img + "gygl/5.7.png"],
        hurl: [this.$img + "gygl/5.8.png"],
        iurl: [this.$img + "gygl/5.9.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 9) {
        this.tolink(0);
      } else if (result >= 9 && result < 44) {
        this.tolink(1);
      } else if (result >= 44 && result < 86) {
        this.tolink(2);
      } else if (result >= 86 && result < 95) {
        this.tolink(3);
      } else {
        this.tolink(4);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>